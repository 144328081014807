@font-face {
  font-family: 'IndieFlower';
  src: url('IndieFlower.eot');
  src: url('IndieFlower.woff') format('woff'),
       url('IndieFlower.ttf') format('truetype'),
       url('IndieFlower.svg#IndieFlower') format('svg'),
       url('IndieFlower.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BloggerSans-Bold';
  src: url('BloggerSans-Bold.eot');
  src: url('BloggerSans-Bold.woff') format('woff'),
  url('BloggerSans-Bold.ttf') format('truetype'),
  url('BloggerSans-Bold.svg#BloggerSans-Bold') format('svg'),
  url('BloggerSans-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Nunito-Regular';
  src: url('Nunito-Regular.eot');
  src: url('Nunito-Regular.woff') format('woff'),
  url('Nunito-Regular.ttf') format('truetype'),
  url('Nunito-Regular.svg#Nunito-Regular') format('svg'),
  url('Nunito-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Bold';
  src: url('Nunito-Bold.eot');
  src: url('Nunito-Bold.woff') format('woff'),
  url('Nunito-Bold.ttf') format('truetype'),
  url('Nunito-Bold.svg#Nunito-Bold') format('svg'),
  url('Nunito-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('Nunito-SemiBold.eot');
  src: url('Nunito-SemiBold.woff') format('woff'),
  url('Nunito-SemiBold.ttf') format('truetype'),
  url('Nunito-SemiBold.svg#Nunito-SemiBold') format('svg'),
  url('Nunito-SemiBold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Italic';
  src: url('Nunito-Italic.eot');
  src: url('Nunito-Italic.woff') format('woff'),
  url('Nunito-Italic.ttf') format('truetype'),
  url('Nunito-Italic.svg#Nunito-Italic') format('svg'),
  url('Nunito-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}




