html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.selection-disabled {
  opacity: 0.5;
}

.react-tour-bubble {
  padding: 50px 30px 30px 30px !important;
}

.ag-react-container {
  font-size: 12px !important;
  color: #4a4a4a !important;
}

.route-section {
  position : static !important;
}

body {
  overflow: auto !important;
}

[title="Limit csökkentése"], [title="Limit növelése"] {
  pointer-events: none;
  visibility: hidden;
}

@media (min-width: 0px) and (max-width: 1279.95px) {
  #jsd-widget {
    display: none;
  }
}

#jsd-widget {
  z-index: 1 !important;
  margin-bottom: 50px !important;
}

@media (max-width: 1919.95px) {
  .ag-react-container {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.ag-row {
  height: auto !important;
  min-height: 50px;
}

.ag-row p {
  font-size: 12px !important;
  color: #4a4a4a !important;
}

.cell-wrap-text {
  line-height: 1.2 !important;
  align-items: center !important;
  display: flex !important;
}

.multi-line .ag-cell-wrapper .ag-cell-value {
  width: 100% !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 12px !important;
  color: #4a4a4a !important;
}

.multi-line.ag-cell-value {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: initial !important;
  white-space: initial !important;
  font-size: 12px !important;
  color: #4a4a4a !important;
  padding-left: 14px !important;
}

.multi-line.ag-cell-value.padding-top {
  padding-top: 6px !important;
}

.padding-top {
  padding-top: 3px !important;
}

.left-header span {
  padding-left: 14px !important;
}

.multi-line.ag-cell-value.left-cell {
  padding-left: 14px !important;
}

.ag-theme-material {
  font-size: 12px !important;
  color: #4a4a4a !important;
}

.ag-auto-width {
  width: auto !important;
  min-width: 100px !important;
}

#___reactour > div:first-child {
  opacity: 0;
  color: #ffffff;
}

.DayPickerNavigation * {
  box-sizing: border-box;
}

.SingleDatePicker_picker__fullScreenPortal {
  z-index: 1100 !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #0045de !important;
  border: 1px double #0045de !important;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  overflow: auto !important;
}

.DateInput_input {
  width: auto !important;
}

* {
  font-family: 'Poppins' !important;
}

.ag-theme-material {
  font-weight: 300 !important;
}

.ag-header-cell-text.ag-header-cell-text.ag-header-cell-text.ag-header-cell-text {
  font-size: 14px !important;
  font-weight: 500;
  font-family: 'Poppins' !important;
  color: black;
  text-transform: none !important;
  padding-bottom: 3px;
  overflow-wrap:break-word;
}

.ag-header-cell-text, .ag-header-cell-label {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: initial !important;
}

.ag-header-cell-resize {
  display:none;
  overflow-wrap:break-word;

}.ag-header-cell-sortable {
  overflow-wrap:break-word;
}

.ag-icon{
  font-family: "agGridMaterial" !important;
}

* {
  font-family: Poppins!important;
}

.ag-icon-checkbox-checked.ag-icon-checkbox-checked.ag-icon-checkbox-checked {
  color: #02BE6F !important;
}

body > iframe {
  display: none;
}
